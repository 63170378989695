import React from "react"
import { graphql } from "gatsby"
import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  List,
  ListIcon,
  ListItem,
  PseudoBox,
  Text,
} from "@chakra-ui/core"
import { Col, Container, Row } from "react-grid-system"
import { FaSquareFull } from "react-icons/fa"

import { Button, Carousel, Hero, SEO } from "../components"

const ListComponent = ({ input }) => {
  return (
    <>
      <Text fontWeight="bold" pb="4" fontSize={{ base: "20px", lg: "xl" }}>
        Dieses Feld umfasst:
      </Text>
      <List>
        {input.list.map((item, i) => (
          <ListItem key={i} mb={{ base: "4", lg: 0 }}>
            <ListIcon
              fontSize="0.8rem"
              mr="6"
              icon={FaSquareFull}
              color="secondary"
            />
            {item}
          </ListItem>
        ))}
      </List>
    </>
  )
}

const AngebotPage = ({ data: { angebot } }) => {
  const {
    seo,
    intro,
    projektarten,
    philosophie,
    banner,
    kunden,
    hero,
  } = angebot.nodes[0]
  if (!angebot) return null
  return (
    <>
      <SEO seo={seo} />
      <Box fontSize={{ lg: "xl" }}>
        <Hero
          small
          text={hero.title}
          image={hero.image.childImageSharp.fluid}
          fluid
        />

        <Box as={Container} my={{ base: "20", lg: "32" }}>
          <Box as={Row}>
            {intro.map((item, i) => {
              return (
                <Col
                  component={PseudoBox}
                  sm={12}
                  lg={4}
                  key={i}
                  mb={{ base: 10, lg: 0 }}
                  _last={{ marginBottom: 0 }}
                >
                  <Heading mb={{ base: "4", lg: "10" }}>
                    {item.headline}
                  </Heading>
                  <Text dangerouslySetInnerHTML={{ __html: item.text }} />
                  <Box display={{ base: "block", lg: "none" }} mt="6">
                    <ListComponent input={item} />
                  </Box>
                </Col>
              )
            })}
          </Box>
          <Box display={{ base: "none", lg: "block" }}>
            <Row>
              {intro.map((item, i) => {
                return (
                  <Col component={Box} sm={12} lg={4} key={i} mt="6">
                    <ListComponent input={item} />
                  </Col>
                )
              })}
            </Row>
          </Box>
        </Box>

        <Box as={Container} my={{ base: "20", lg: "32" }}>
          <Row>
            <Col>
              <Box
                border={{ base: "15px solid", lg: "20px solid" }}
                borderColor={{ base: "secondary", lg: "secondary" }}
                py={{ base: "6", lg: "20" }}
                px={{ base: "8", lg: "24" }}
              >
                <Text dangerouslySetInnerHTML={{ __html: banner[0].text }} />
              </Box>
            </Col>
          </Row>
          <Row>
            <Col>
              <Box
                mt={{ base: "10", lg: "20" }}
                textAlign={{ base: "center", lg: "right" }}
                w="100%"
              >
                <Button as="a" href="mailto:info@moiin.eu" target="_blank">
                  Jetzt Kontakt aufnehmen
                </Button>
              </Box>
            </Col>
          </Row>
        </Box>
        <Box my={{ base: "20", lg: "32" }}>
          <Box as={Container}>
            <Box as={Row}>
              <Col>
                <Heading fontSize={{ base: "3xl", xl: "5xl" }} pb="10">
                  Unsere Projektarten
                </Heading>
              </Col>
            </Box>
          </Box>

          <Box bg="secondary" color="white" py={{ base: "16", lg: "20" }}>
            <Box as={Container}>
              <Box as={Row}>
                {projektarten.map((item, i) => {
                  return (
                    <Col
                      component={PseudoBox}
                      sm={12}
                      lg={4}
                      key={i}
                      mb={{ base: "10", lg: 0 }}
                      _last={{ marginBottom: 0 }}
                    >
                      <Heading as="h3" pb="8">
                        {item.headline}
                      </Heading>
                      <Text dangerouslySetInnerHTML={{ __html: item.text }} />
                    </Col>
                  )
                })}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box as={Container}>
          <Heading fontSize={{ base: "3xl", xl: "5xl" }} pb="10">
            {philosophie[0].headline}
          </Heading>
          <Text dangerouslySetInnerHTML={{ __html: philosophie[0].text }} />
        </Box>

        {/* Carousel */}
        <Box my={{ base: "20", lg: "32" }}>
          <Container component={Box} textAlign="center">
            <Heading fontSize={{ base: "3xl", xl: "5xl" }} pb="10">
              Unsere Kunden
            </Heading>
          </Container>
          <Carousel>
            {kunden.map((item, i) => {
              return (
                <Col key={i}>
                  <Link href={item.link} isExternal>
                    <Flex
                      direction="column"
                      align="center"
                      justify="center"
                      h="100%"
                    >
                      <Image
                        src={item.logo.publicURL}
                        alt={item.alt}
                        w="190px"
                      />
                      <Text fontWeight="bold" pt="12">
                        {item.name}
                      </Text>
                    </Flex>
                  </Link>
                </Col>
              )
            })}
          </Carousel>
        </Box>
      </Box>
    </>
  )
}

export const query = graphql`
  {
    angebot: allAngebotJson {
      nodes {
        seo {
          title
          description
        }
        hero {
          title
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        intro {
          headline
          list
          text
        }
        philosophie {
          headline
          text
        }
        projektarten {
          headline
          text
        }
        banner {
          text
        }
        kunden {
          alt
          logo {
            publicURL
          }
          name
          link
        }
      }
    }
  }
`

export default AngebotPage
